import React, { useState } from "react";
import "../assets/scss/core/sections/_contact-us.scss";
import Image from "../components/elements/Image";

const ContactUs = (
) => {

   const [val,setVal] =  useState("")
   const [val2,setVal2] =  useState("")
   const [val3,setVal3] =  useState("")

  return (
   <div className="hero section center-content illustration-section-01 reveal-from-center" data-reveal-delay="600">
    <div className="container contact-hero">
        <div className="contact-left">
            <h2 className="reveal-from-bottom" data-reveal-delay="600">Drop us a line</h2>
            <form>
                <div className="input-wrapper reveal-from-bottom" data-reveal-delay="600">
                    <label for="fname">Full Name</label>
                    <input type="text" id="fname" name="fname" value={val} onChange={(e)=>setVal(e.target.value)} placeholder="What's your full name?" />
                </div>
                <div className="input-wrapper reveal-from-bottom" data-reveal-delay="600">
                    <label for="email-address">Email address</label>
                    <input type="email" id="email-address" name="email-address" value={val2} onChange={(e)=>setVal2(e.target.value)} placeholder="you@example.com" />
                </div>
                <div className="input-wrapper reveal-from-bottom" data-reveal-delay="600">
                    <label for="message">Message</label>
                    <textarea id="message" name="message" value={val3} onChange={(e)=>setVal3(e.target.value)} placeholder="Write your message for the team here" />
                </div>
                <input className="submit reveal-from-bottom" data-reveal-delay="600" type="submit" value="Submit"/>
            </form>
        </div>
        <div className="contact-right reveal-from-bottom" data-reveal-delay="600">
            <Image
                src={require('./../assets/images/contact-us.jpeg')}
                alt=""
                width={"100%"}
            />
        </div>
    </div>
   </div>
  );
};

export default ContactUs;
