import React from "react";
import classNames from "classnames";
// import { SectionTilesProps } from "../utils/SectionProps";

import "../assets/scss/Privacy.css"

// import "./"
// const propTypes = {
//   ...SectionTilesProps.types,
// };

// const defaultProps = {
//   ...SectionTilesProps.defaults,
// };
const Privacy = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  // const tilesClasses = classNames(
  //   "tiles-wrap center-content",
  //   pushLeft && "push-left"
  // );

  return (
    <section {...props} className={outerClasses}>
      <div className="main_container">

      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <div
              className="title-featured reveal-from-bottom text_start"
              data-reveal-delay="400"
            >
             Privacy Policy :
            </div>
          
        </div>
        <div className="">

 <p>
   Privacy CofoSoft (“CofoSoft“, “we”, “our” or “us“) welcomes you (the “User(s)” or “you”) to our website at https://www.cofosoft.com (the “Site”) and to our product services (the “Services”).The terms which are not defined herein, shall have the meaning ascribed to them in our Terms of Use available at https://www.cofosoft.com/terms which this Privacy Policy is incorporated thereto by reference (together, the “Terms”).
    </p>

<p className="font_clas">
  BY ACCESSING THE SITE AND/OR SERVICES AND/OR USING THE SITE AND/OR SERVICES, YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY
(THE “PRIVACY POLICY”), INCLUDING TO THE COLLECTION AND PROCESSING OF YOUR PERSONAL INFORMATION (AS DEFINED BELOW).
</p>
<p className="title_bold">
IF YOU DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT USE THE SERVICES.
</p>

<p className="title_bold">
  1. Data We may collect
</p>

<p>
We may collect data or information from our Users. The first type of information is non-registered and anonymous information (“Non-Personal Information”).
We are not aware of the identity of the User from which we have collected the Non-Personal Information. Non-Personal Information is any unconcealed information
which is available to us while Users are using the Services. Non-Personal Information which is being gathered consists of technical information and behavioral
information, and may contain, among other things, the activity of the User on the Services.
</p>


<p>
The second type of information is individually registered information (“Personal Information”). This information may identify an individual or may be of a
private and/or sensitive nature such as User’s first name, last name,email address and public profile in Social Media Account mainly for operating the Services
and enhancing the Users experience.We do not collect any Personal Information from you or related to you without your approval, which is obtained
through your active acceptance to the Terms.

</p>

<p className="title_bold">
HOW WE COLLECT INFORMATION ON OUR USERS ?
</p>

<p className="title_bold">
  There are two main methods we use :
 </p>

<p>
  <span className="title_bold">
    
(1) Use of the Services :  
  </span>

- When you use the Services, we collect Non-Personal Information derived from information we receive from third party providers.We also collect Non-Personal Information when you access or use the our Services. In other words, we are aware of your usage of the Services and may gather,
collect and record the information relating to such usage, either independently or through the help of third-party Services as detailed below.
We will collect Personal Information when you register for use of the Services either by information you input directly.


</p>

<p className="font_clas">

IT IS THE YOUR SOLE RESPONSIBILITY TO OBTAIN ANY AND ALL CONSENTS REQUIRED UNDER ANY APPLICABLE LAWS, REGARDING THE ACCESSSING OF ANY PERSONAL INFORMATION OF OTHERS
WHICH IS PART OF THE SERVICES, AND TO ADHERE TO ANY APPLICABLE STATE LAWS REGARDING SUCH INFORMATION.
</p>

<p>
<span className="title_bold">
(2) Voluntarily Provided :
</span>
- We collect Personal Information which you voluntarily provide when you use the Services. By registering for an Account you must provide
us with your  first name, email address. We may store the Personal Information and Non-Personal Information either independently
or through our authorized third-party Services providers as detailed below.
</p>

<p className="title_bold">

HOW WE USE YOUR PERSONAL INFORMATION ?
</p>

<p>
Your information is collected for purposes, which include without limitation the following : 
</p>

<ul>

 <li>
  
  to provide you with and operate the Services,
  </li>
 <li>
  
  to develop, improve and custom the Services and offering available through the Services; 
  </li>
<li>
  
  to be able to contact User for the purpose of providing them with technical assistance, support, and collect feedback; 
  </li>
 <li>
  
  to be able to reply to User’s online queries or governmental agencies’ requests;
  </li>
 <li>
  
  for customization and improvement of the Services’ accuracy and interface; and
  </li>
 <li>
  
  to comply with our legal or statutory obligations.
  </li> 
</ul>

<p className="title_bold">
SHARING INFORMATION WITH THIRD PARTIES
</p>

<p>

CofoSoft will not share any Personal Information it collects with any third party. Notwithstanding, CofoSoft may disclose Personal Information in the
following cases :
</p>

<ul>

  <li> 
     to satisfy any applicable law, regulation, legal process;
    </li>
  <li> 
     to enforce this Privacy Policy and/or the Terms of Use, including investigation of potential violations thereof;
    </li>
  <li> 
     to detect, prevent, or otherwise address fraud, security or technical issues;
    </li>
 <li> 
   to respond to User’s support requests;
  </li>
  <li> 
     respond to claims that any content available on CofoSoft Services violates the rights of third-parties;
    </li>
 <li> 
   to respond to claims that contact information (e.g. name, e-mail address, etc.) of a third-party has been posted or transmitted without their consent or as a form of harassment;
  </li>
  <li> 
     to protect the rights, property, or personal safety of CofoSoft, its Users, or the general public; 
    </li>
 <li> 
    to authorized third party service providers solely for the purpose of providing you with the Services.
  </li>
</ul>

<p className="title_bold">
  UPDATE OR DELETION OF PERSONAL INFORMATION
  </p>

<p>
If for any reason you wish to modify or delete your Personal Information please contact us at: https://www.cofosoft.com/terms , and we will make reasonable efforts to
modify or delete any such Personal Information pursuant to any applicable privacy laws. It is your sole responsibility to store any of your Creative provided
via the the cofosoft Services.Note that unless you instruct us otherwise we may retain your Personal Information as long as necessary.
</p>

<p>
THIRD PARTY SOFTWARE/SERVICES
</p>

<p>
While using the Services we may be using third party software and/or Services for various needs, in order to collect and/or process the information detailed
herein (the “Third Party Services Provider”). Note that these are third party software and/or Services providers and cofosoft does not take any liability about
the information collection policies of these providers, or about any kind of issue, legal or otherwise, relating thereto.
</p>

<p className="title_bold">
  USE OF COOKIES
  </p>

<p>
When you access or use our Site, the cofosoft may use technologies such as “cookies”, which store certain information on  your computer and which will allow us to enable automatic activation of certain features, and make your Services experience much more convenient
and effortless. The cookies will assist the cofosoft to enable automatic diagnosis by the application. It is easy to prohibit the Local Storage.
Most browsers will allow you to erase cookies from your computer’s hard drive, block acceptance of cookies, or receive a warning before a cookie is stored.
However, if you block or erase cookies your online experience may be limited.
</p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Privacy;
