import React from "react";
import "../assets/scss/core/sections/_about-us.scss";
import Image from "../components/elements/Image";

const AboutUs = (
) => {
  return (
    <div className="hero section center-content illustration-section-01">
    <div className="container about">
      <div className="about-title">
        <h2 className="reveal-from-bottom" data-reveal-delay="400">About Us</h2>
        <p className="reveal-from-bottom" data-reveal-delay="500">Lorem Ipsum is simply dummy</p>
      </div>
      <div className="about-wrapper reveal-from-bottom" data-reveal-delay="600">
        <div className="about-left">
          <h2>Our Vision</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetti of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
          <p>Various versions have evolved over the years, sometimes by accident</p>
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
        </div>
        <div className="about-right">
          <Image
            src={require('./../assets/images/about.png')}
            alt=""
            width={"100%"}
          />
        </div>
      </div>
      <div className="about-wrapper right reveal-from-bottom" data-reveal-delay="800">
        <div className="about-left">
          <h2>Our Vision</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetti of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
          <p>Various versions have evolved over the years, sometimes by accident</p>
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
        </div>
        <div className="about-right">
          <Image
            src={require('./../assets/images/unsplash.png')}
            alt=""
            width={"100%"}
          />
        </div>
      </div>
      <div className="about-wrapper reveal-from-bottom" data-reveal-delay="600">
        <div className="about-left">
          <h2>Our Vision</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetti of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
          <p>Various versions have evolved over the years, sometimes by accident</p>
          <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
        </div>
        <div className="about-right">
          <Image
            src={require('./../assets/images/bg-imag.png')}
            alt=""
            width={"100%"}
          />
        </div>
      </div>
    </div>
   </div>
  );
};

export default AboutUs;
