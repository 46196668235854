import React from "react";
import classNames from "classnames";
// import { SectionTilesProps } from "../utils/SectionProps";
import "../assets/scss/terms.css";

const Terms = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="main_container">
        <div className="container">
          <div className={innerClasses}>
            {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
            <div
              className="title-featured reveal-from-bottom text_start"
              data-reveal-delay="400"
            >
              Terms and Conditions :
            </div>
          </div>

          <p className="title_bold font_big">Welcome to CofoSoft</p>

          <p>
            These Terms of Service (“Terms”) will govern your access to and use
            of our service, software, application, fuctionality, or program
            created by CofoSoft (collectively, “Services”).{" "}
          </p>

          <p>
            Please read these following terms and conditions carefully before
            using CofoSoft website or any of our Services. By using CofoSoft
            Services, you (“you” or “User”) signify that you have read,
            understand, and agree to be legally bound by these Terms. Violation
            of any of the terms below will result in the termination of your
            account relating to CofoSoft. The terms “we”, “us” or “our” shall
            refer to CofoSoft. The terms “you”, “your”, “user” or “customer”
            shall refer to any individual or entity who accepts this Terms, or
            purchases our Services.
          </p>

          <p className="title_bold">Changes of Terms, or Services : -</p>

          <p>
            CofoSoft reserves the right to modify this Terms at any time.
            However, we will post any changes on this site and, if the changes
            are significant, we will have a prominent notice. Your continued use
            of the Services following such modifications shall constitute your
            consent to the modified Terms. If you don’t agree to be bound by
            this Terms at last revised, then you may not use (or continue to
            use) the Services. Therefore, you should periodically check for the
            most latest version. It is highlighted that CofoSoft retains the
            right to modify, or discontinue any aspect of CofoSoft Site or
            Services, at any time. We also reserve the right to create limits on
            use, suspend or terminate users at our sole discretion at any time.
          </p>

          <p className="title_bold">Your Registration Obligations : -</p>

          <p>
            To access to and use of our Products or Services, you will be
            required to create an account in our app. When registering to
            CofoSoft, you represent and warrant to CofoSoft that all information
            you summit when you create your account is accurate, current and
            complete and you will keep these information accurate, current and
            complete.
          </p>

          <p>
            If we find out that your provided information is inaccurate, not
            current or incomplete, your account will be suspended or terminated.
          </p>

          <p>
            You are responsible for maintaining the security of your account and
            the accompanying password. You are solely responsible for all
            activities that occur on your account, CofoSoft will not be liable
            for any loss or breach of confidentiality from your failure to
            comply with this security obligation.
          </p>

          <p className="title_bold">Availability of Website/Services : -</p>

          <p>
            We will make 100% efforts to ensure our services is available to you
            on a 24/7 basis, which means you can access to our Services at any
            time.
          </p>

          <p className="title_bold">
            However, you must acknowledge and accept that there may be times
            when our Site/Services are inaccessible or inoperable for some
            following reasons:
          </p>

          <p>
            malfunctions, periodic maintenance, repairs or replacement; or
            caused beyond our reasonable control, but not limited to,
            interruption or failure of telecommunication or digital transmission
            links, hostile network attacks, or other failures.
          </p>

          <p className="title_bold">Discontinued Services; EOL : -</p>

          <p>
            Although CofoSoft is continually provides the lifespan of our
            Services, we may discontinue one of our Services when it reaches its
            End-of-Life (EOL). In these cases, CofoSoft will no longer offer any
            support to that service, effective on the EOL date.
          </p>

          <p>
            For any Service being planned for EOL, we will issue an EOL
            announcement one (1) calendar month or earlier prior to the EOL
            date. It is your responsibility to take all necessary steps to
            replace the Service by migrating to a new Service before the EOL
            date.
          </p>

          <p className="title_bold">Refund of Charges : -</p>

          <p>
            All Fees paid by the User are non-refundable. The User is
            responsible for paying all applicable Fees, whether or not it has
            actively used the Service. Fees and Payment;
          </p>

          <p className="title_bold">Automatic Renewal : -</p>

          <p>
            CofoSoft retains the right to change its prices and fees at any time
            without notice to users. These price modifications will be posted
            online at this Site. If you are a monthly or yearly subscriber,
            changes in prices and fees will be applied to your next renewal
            term.
          </p>

          <p className="title_bold">Disclaimer of Warranties : -</p>

          <p>
            The Services are available “AS-IS”. Your access to and use of the
            Services are at your own risk. You understand and agree that the
            Services are provided to you on an “AS IS” and “AS AVAILABLE” basis.
            CofoSoft make no warranty or representation and disclaim all
            responsibility and liability for the accuracy, completeness,
            availability, security or reliability of the Services.
          </p>

          <p>
            In no circumstance and in no event shall CofoSoft be liable to you
            for any direct, in direct, incidental, special, punitive or
            consequential damages or loss profits.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Terms;
