import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
// import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    'border-top',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap wrapper',
    pushLeft && 'push-left'
  );

  // const sectionHeader = {
  //   title: 'Customer testimonials',
  //   paragraph: 'Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.'
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className='reveal-from-bottom why-title' data-reveal-delay="400">Why you choose us</div>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className='img-wrapper'>
                <Image
                  src={require('./../../assets/images/img1.png')}/>
                </div>
                <div className="text-wrapper">
                  <p className='heading-para'>Dashboard Insight</p>
                  <p className="text-sm mb-0">
                    Dashboard Insight connect your shopify store and consolidates all your data into a single location.
                    </p>
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Roman Level</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="text-wrapper left">
                  <p className='heading-para'>Manage Product listing</p>
                  <p className="text-sm mb-0">
                    Our app simplifying the product listing on Etsy using our predefined templates and profiles. You can reuse the same templates again and again.
                    <br/>
                    It saves your valuable time during the product listing.
                    </p>
                </div>
                <div className='img-wrapper left'>
                <Image
                  src={require('./../../assets/images/img2.png')}/>
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Diana Rynzhuk</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className='img-wrapper'>
                <Image
                  src={require('./../../assets/images/img3.png')}/>
                </div>
                <div className="text-wrapper">
                  <p className='heading-para'>Manage Order management</p>
                  <p className="text-sm mb-0">
                    Orders on Etsy Marketplace can be imported to Shopify automatically so you can fulfill right on your Shopify Store.
                    </p>
                </div>
                {/* <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Ben Stafford</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;