import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import ContactUs from './views/ContactUs';
import AboutUs from './views/AboutUs';
import Pricing from './views/Pricing';

// import "./assets/scss/Privacy.css"

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/privacy-policy" layout={LayoutDefault} component={Privacy} />
          <AppRoute exact path="/terms" layout={LayoutDefault} component={Terms} />
          <AppRoute exact path="/contact-us" layout={LayoutDefault} component={ContactUs} />
          {/* <AppRoute exact path="/pricing" layout={LayoutDefault} component={Pricing} /> */}
          <AppRoute exact path="/about-us" layout={LayoutDefault} component={AboutUs} />
        </Switch>
      )} />
  );
}

export default App;