import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
// import ButtonGroup from '../elements/ButtonGroup';
// import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import { Link } from 'react-router-dom';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <div  {...props} className={outerClasses}>
    <section className='bg-banner'>
      <div className="container reveal-from-bottom" data-reveal-delay="200">
        <div className={innerClasses}>
          <div className="hero-content">
            <h3 className="mt-0 mb-52 reveal-from-bottom banner-title" data-reveal-delay="400">
            Update your Inventory and order details synched in real time between Shopify store and Etsy Marketplace
              {/* Landing template for <span className="text-color-primary">startups</span> */}
            </h3>
            <div className="container-xs flex reveal-from-bottom" data-reveal-delay="400">
              <div>
              <Image
                src={require('./../../assets/images/shopify-icon.svg')}
                alt="icon"
                width={200}
                height={55} />
              </div>
              <div>
              <Image
                src={require('./../../assets/images/arrow-icon.svg')}
                alt="icon"
                width={100}
                height={56} />
              </div>
              <div>
              <Image
                src={require('./../../assets/images/etsy-icon.svg')}
                alt="icon"
                width={200}
                height={100} />
              </div>
              {/* <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://cruip.com/">
                    Get started
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://github.com/cruip/open-react-template/">
                    View on Github
                    </Button>
                </ButtonGroup>
              </div> */}
            </div>
            <div className='reveal-from-bottom' data-reveal-delay="400">
              <Link to="#" className='contact-btn'>Download App</Link>
            </div>
          </div>
          {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/video-placeholder.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div> */}
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" />
        </div>
      </div>
    </section>
    </div>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;