import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import Logo from './partials/Logo';
// import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import { Link } from 'react-router-dom';
import Image from '../elements/Image';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
      data-reveal-delay="200"
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <Link to="/">
              <Image
                src={require('./../../assets/images/logo.svg')}
                alt="Open"
                width={140}
                height={40} />
            </Link>
            <FooterSocial />
          </div>
          <div className='bottom-footer'>
            <div className='link-footer'>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms">Terms</Link>
            </div>
            <div className='rights-cofo'>©2022 All rights reserved to CofoSoft</div>
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            {/* <FooterNav />
            <div className="footer-copyright">Made by <a href="https://cruip.com">Cruip</a>. All right reserved</div> */}
            <div className="footer-copyright">The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.</div> 
          </div>
          
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;